import React from "react"

import Star from "./star"

// export default class extends React.Component {
//   createHtml = () => {
//     let arr = []

//     for (let i = 0; i < 5; i++) {
//       if (i < this.props.Level) {
//         arr.push(<span key={i} className="uk-margin-small-right uk-text-primary" data-uk-icon="icon: star; ratio:1.5" />)
//       } else {
//         arr.push(<span key={i} className="uk-margin-small-right uk-text-muted" data-uk-icon="icon: star" />)
//       }
//     }
//     return arr
//   }

//   render() {
//     return <div>{this.createHtml()}</div>
//   }
// }

// export default class extends React.Component {
//   render() {
//     return <progress className="uk-progress" value={this.props.Level} max="5" />
//   }
// }

export default class extends React.Component {
  createHtml = () => {
    let arr = []

    for (let i = 0; i < 5; i++) {
      if (i < this.props.Level) {
        arr.push(
          <span key={i} className="uk-margin-small-right">
            <Star fillColor="#888" strokeColor="#888" />
          </span>
        )
      } else {
        arr.push(
          <span key={i} className="uk-margin-small-right">
            <Star fillColor="none" strokeColor="#888" />
          </span>
        )
      }
    }
    return arr
  }

  render() {
    return <div>{this.createHtml()}</div>
  }
}
