import React from "react";
import { navigate } from "gatsby";
import Img from "gatsby-image";

import { TriangleBottomRight } from "./triangles";

export default props => (
  <div>
    <div
      className="uk-card uk-card-default uk-transition-toggle uk-box-shadow-hover-large pointer"
      onClick={() => navigate(props.LinkUrl)}
      onKeyDown={() => navigate(props.LinkUrl)}
      role="link"
      tabIndex="0"
    >
      <div className="uk-card uk-card-primary uk-transition-toggle uk-box-shadow-hover-large">
        <div className="uk-card-body uk-padding-small">
          <h4 className="uk-text-truncate uk-text-center">
            {props.Title}
            <br />
            <small>{props.Subtitle}</small>
          </h4>
        </div>
        <div className="uk-card-media-bottom uk-cover-container">
          <div className="uk-transition-scale-up uk-transition-opaque">
            <Img fluid={props.ImageFluid} />
          </div>
        </div>
        {props.Label && <TriangleBottomRight Label={props.Label} />}
      </div>
    </div>
  </div>
);
