import React from "react"

function Star({ strokeColor, fillColor }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="star">
      <polygon
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="1.01"
        points="10 2 12.63 7.27 18.5 8.12 14.25 12.22 15.25 18 10 15.27 4.75 18 5.75 12.22 1.5 8.12 7.37 7.27"
      ></polygon>
    </svg>
  )
}

export default Star
