import React from "react";
import { Link } from "gatsby";

export default props => (
  <Link
    to={props.To}
    className="uk-label uk-border-rounded uk-button uk-button-primary uk-button-small uk-margin-small-right uk-margin-small-bottom"
  >
    {props.children}
  </Link>
);
