import React from "react"
import { navigate } from "gatsby"
import Img from "gatsby-image"

import Stars from "../components/level"

export default props => (
  <div>
    <div
      className="uk-card uk-card-default uk-transition-toggle uk-box-shadow-hover-large pointer"
      onClick={() => navigate(props.LinkUrl)}
      onKeyDown={() => navigate(props.LinkUrl)}
      role="link"
      tabIndex="0"
    >
      <div className="uk-card uk-card-default uk-transition-toggle uk-box-shadow-hover-large">
        <div className="uk-card-media-top uk-cover-container">
          <div className="uk-transition-scale-up uk-transition-opaque">
            <div className="uk-card-badge uk-label">{props.Label}</div>
            <Img fluid={props.ImageFluid} />
          </div>
        </div>
        <div className="uk-card-body uk-padding-small uk-text-center">
          <Stars Level={props.Level} />
        </div>
      </div>
    </div>
  </div>
)
