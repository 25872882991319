import React from "react";
import { Link, graphql } from "gatsby";

import Head from "../components/head";
import Layout from "../components/layout";
import Section from "../components/section";
import Grid from "../components/grid";
// import Card from "../components/card";
import CardSkill from "../components/card-level-only";
import CardProject from "../components/card-title-top";
import Lightbox from "../components/lightbox";
import ButtonTag from "../components/button-tag";

const randomSort = arr => arr.sort(() => Math.random() - 0.5);

export default ({ data }) => {
  const node = data.markdownRemark;
  const skills = data.skills ? randomSort(data.skills.edges) : null;
  const projects = data.projects ? data.projects.edges : null;

  return (
    <div>
      <Head
        PageTitle={node.frontmatter.title + " - " + node.frontmatter.category}
        PageDescription={node.excerpt}
        PageSlug={node.fields.slug}
        PageImage={node.frontmatter.cover_image.childImageSharp.fluid.src}
      />

      <Layout>
        <Section Style="default">
          <div>
            <h3>
              <Link to={"/" + node.frontmatter.categoryId + "/"}>
                <small>{node.frontmatter.category}</small>
              </Link>{" "}
              <span
                className="uk-margin-small-right"
                data-uk-icon="chevron-right"
              />{" "}
              {node.frontmatter.title}
            </h3>

            <div className="uk-width-1-2@s uk-align-center uk-align-right@s uk-margin-remove-adjacent">
              <Lightbox
                ImageUrl={
                  node.frontmatter.cover_image.childImageSharp.original.src
                }
                ImageFluid={node.frontmatter.cover_image.childImageSharp.fluid}
              />
            </div>

            <div dangerouslySetInnerHTML={{ __html: node.html }} />

            <hr />
            <p>
              <em>Compétences</em> :
            </p>
            <div>
              {skills &&
                skills.map(({ node }) => {
                  return (
                    <ButtonTag key={node.id} To={node.fields.slug}>
                      {node.frontmatter.titleId}
                    </ButtonTag>
                  );
                })}
            </div>
          </div>

          <hr />
          <h4 id="projects" className="uk-text-center uk-margin-medium">
            Projets et réalisations associés :
          </h4>
          <Grid Col="3">
            {projects &&
              projects.map(({ node }) => {
                return (
                  <div key={node.id}>
                    <CardProject
                      ImageFluid={
                        node.frontmatter.cover_image.childImageSharp.fluid
                      }
                      Label={node.frontmatter.date}
                      Title={node.frontmatter.title}
                      Subtitle={node.frontmatter.subtitle}
                      // Intro={node.excerpt}
                      // LinkUrl={node.fields.slug}
                      LinkUrl={"/projects/" + node.frontmatter.titleId}
                      LinkText="Découvrir"
                    />
                  </div>
                );
              })}
          </Grid>
        </Section>

        <Section Style="muted">
          <h4 id="skills" className="uk-text-center uk-margin-medium">
            Compétences et techologies associées :
          </h4>
          <Grid Col="4">
            {skills &&
              skills.map(({ node }) => {
                return (
                  <div key={node.id}>
                    <CardSkill
                      ImageFluid={
                        node.frontmatter.cover_image.childImageSharp.fluid
                      }
                      Level={node.frontmatter.level}
                      Title={node.frontmatter.title}
                      // Intro={node.excerpt}
                      LinkUrl={node.fields.slug}
                      LinkText="Découvrir"
                    />
                  </div>
                );
              })}
          </Grid>
        </Section>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query($service: String!) {
    markdownRemark(frontmatter: { titleId: { eq: $service } }) {
      html
      frontmatter {
        title
        titleId
        cover_image {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 848, maxHeight: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM YYYY")
        category
        categoryId
        services
        skills
      }
      fields {
        slug
      }
      excerpt(pruneLength: 320)
    }
    skills: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: {
        frontmatter: {
          services: { eq: $service }
          categoryId: { eq: "skills" }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            titleId
            cover_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 225) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "YYYY")
            category
            categoryId
            services
            skills
            level
          }
          fields {
            slug
          }
          excerpt(pruneLength: 80)
        }
      }
    }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          services: { eq: $service }
          categoryId: { eq: "projects" }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            titleId
            cover_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 225) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "YYYY")
            category
            categoryId
            services
            skills
          }
          fields {
            slug
          }
          excerpt(pruneLength: 80)
        }
      }
    }
  }
`;
