import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

export default props => (
  <div>
    <div data-uk-lightbox>
      <Link to={props.ImageUrl}>
        <div className="uk-overflow-hidden uk-transition-toggle">
          {/* <img
            className="uk-transition-scale-up uk-transition-opaque"
            alt=""
            data-src={props.Image}
            data-uk-img
          /> */}
          <Img
            fluid={props.ImageFluid}
            className="uk-transition-scale-up uk-transition-opaque"
          />
        </div>
      </Link>
    </div>
  </div>
);
